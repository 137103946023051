import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/mnt/fstack_dev/nykenshinkai.com/nyksk/src/components/default-mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "314px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d1241db4456b89c08a0c9aad15915844/2540d/toshiki-kishi.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "179.16666666666669%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d1241db4456b89c08a0c9aad15915844/8ac56/toshiki-kishi.webp 240w", "/static/d1241db4456b89c08a0c9aad15915844/715b3/toshiki-kishi.webp 314w"],
              "sizes": "(max-width: 314px) 100vw, 314px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d1241db4456b89c08a0c9aad15915844/09b79/toshiki-kishi.jpg 240w", "/static/d1241db4456b89c08a0c9aad15915844/2540d/toshiki-kishi.jpg 314w"],
              "sizes": "(max-width: 314px) 100vw, 314px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d1241db4456b89c08a0c9aad15915844/2540d/toshiki-kishi.jpg",
              "alt": "Toshiki Kishi",
              "title": "Toshiki Kishi",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h1><div align="center">{`In memory of Kishi sensei`}</div></h1>
    <h1><div align="center">{`Rest In Peace`}</div></h1>
    <h1><div align="center">{`1964 - 2002`}</div></h1>
    <p>{`Toshiki Kishi, kendo 5th dan, was a student at NYC
Kendo Club. Kataoka Sensei described his kendo
as “strong, fast, and beautiful.”
With the support of Kataoka-sensei, Kishi-sensei
formed the NYC Kendo Club Midtown Kenshinkai
in March, 2002. His reputation as a kendo player
attracted many fine players. This club became what
we now know as New York Kenshinkai.`}</p>
    <p>{`Like a perfect cherry blossom falling in a spring
wind, Kishi Sensei departed this mortal coil on
October 7, 2002. He was 34 years old. Since
his passing, Kenshinkai has continued to grow and
mature and will continue his legacy for years to
come.`}</p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      